// Colours
$color-marker: #b6b6b6 !default;
$color-marker-active: #2196F3 !default;
$color-marker-complete: #1976D2 !default;
$color-marker-hover: #56ADF5 !default;

$color-path: #b6b6b6 !default;
$color-path-complete: #868686 !default;

$color-text: #333 !default;
$color-marker-text: #fff !default;


// Sizing
$marker-size: 24px !default;
$marker-size-half: ceil($marker-size / 2);
$marker-size-third: ceil($marker-size / 3);
$marker-size-quarter: ceil($marker-size / 4);
$marker-spacing: 8px !default;

$path-height: 4px !default;
$path-position: $marker-size-half - ($path-height / 2);

$text-padding: 8px !default;
$text-padding-X: $marker-size-third !default;
$text-padding-Y: $text-padding !default;
$text-padding--vertical: $text-padding*1.5 !default;

$progress-tracker-padding: 4px !default;


// Dots connecting text to markers
$dot-size: 12px;
$dot-size-half: $dot-size/2;
$dot-spacing: 6px;
$dot-levels: 12;

$color-dot: $color-path;


// Animations/Transitions
$animation-duration: 0.3s !default;
$ripple-color: rgba(0, 0, 0, 0.3) !default;
