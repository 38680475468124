[dir="rtl"] {
  .progress-marker {
    &::after {
      right: auto;
      left: -#{$marker-size-half};
    }
  }

  .progress-tracker--center {
    .progress-marker {
      &::after {
        left: -50%;
      }
    }
  }
}